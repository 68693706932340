body {
	top: 0 !important;
}
.goog-te-banner-frame {
	display: none !important;
	visibility: hidden !important;
}

.goog-logo-link{
	display:none !important;
}

.goog-te-gadget{
	color:transparent!important;
}
